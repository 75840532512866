import baseComponent from "@/scripts/baseComponent";
import commonActionHandle from "@/commonActionHandle";
import authApi from '@/api/common/auth';
import usersApi from '@/api/common/users';
import { mapState } from "vuex";
import ColumnUser from "../../views/components/column/User.vue";
import ColumnBalance from "../../views/components/column/Balance.vue";
const queryString = require('query-string');

export default {

  components: {
    ColumnUser,
    ColumnBalance,
  },

  extends: baseComponent,

  props: {

  },

  data() {
    return {
      pagination: {
        querySearch: '',
        username: '',
        action: '',
        orderBy: 'CREATED_AT',
        directionSort: 'DESC',
        pageIndex: 1,
        pageSize: 50,
        outRowsNumber: 0,
      },
      listData: {},
    };
  },

  computed: {
    ...mapState({
      listStaff: state => state.staffs,
      listServicePack: state => state.common.listServicePack,
      decentralization: state => state.auth.decentralization,
    }),
  },

  created() {

  },

  mounted() {
    this.getData(this.pagination);
  },

  methods: {

    // Close this pop-up
    closeThisPopup() {
      this.listData = {};
      this.closePopup('system-manager-act-in-doubt');
    },

    // Action
    performAction(param) {
      switch (param.action) {
        case 'refreshList':
          this.getData(this.pagination);
          break;
        case 'blockUser':
          this.blockUser(param.data.userId);
          break;
        case 'following':
          this.changeStatus(1, param.data.userId);
          break;
        case 'processed':
          this.changeStatus(100, param.data.userId);
          break;
      }
    },

    // Get data
    getData(request) {
      this.feedStatusLoading(0);
      authApi.systemManagerActInDoubtView(queryString.stringify(request))
        .then((res) => {
          try {
            let responseData = res.data;
            let pagingItem = responseData.pagingItem;
            this.listData = responseData.data;
            this.listData.forEach(element => {
              console.log("log---", element)
              element.columnUser = this.mapColumnUser(element);
            });
            this.pagination.orderBy = pagingItem.orderBy;
            this.pagination.directionSort = pagingItem.directionSort;
            this.pagination.pageIndex = pagingItem.pageIndex;
            this.pagination.pageSize = pagingItem.pageSize;
            this.pagination.outRowsNumber = pagingItem.outRowsNumber;
            this.feedStatusLoading(1);
          } catch (error) {
            console.log("getData -> error", error)
            this.feedStatusLoading(3);
          }
        })
        .catch(error => {
          console.log("getData -> error", error)
          this.feedStatusLoading(3);
        });
    },

    // Change status
    changeStatus(status, userId) {
      let request = {
        userId: userId,
        status: status,
        deviceInformation: JSON.stringify(this.getInformationClient()),
      }
      let description = '';
      switch (status) {
        case this.$actInDoubtListStatus.processing:
          description = "Are you sure you want to mark following for this user ?";
          break;
        case this.$actInDoubtListStatus.processed:
          description = "Are you sure you want to mark resolved for this user ?";
          break;
      }
      commonActionHandle.showCustomConfirm(
        "Confirm",
        description,
        () => {
          authApi.systemManagerActInDoubtModify(request)
            .then((res) => {
              try {
                this.performAction({ action: 'refreshList' })
                this.feedStatusLoading(1);
              } catch (error) {
                console.log("getData -> error", error)
                this.feedStatusLoading(3);
              }
            })
            .catch(error => {
              console.log("getData -> error", error)
              this.feedStatusLoading(3);
            });
        }
      );
    },

    // Block user
    blockUser(userId) {
      let request = {
        isDeactive: true,
        userId: userId,
        deviceInformation: JSON.stringify(this.getInformationClient()),
      }
      commonActionHandle.showCustomConfirm(
        "Confirm",
        "Are you sure you want block this user ?",
        () => {
          usersApi.deactiveUser(request)
            .then((res) => {
              try {
                this.performAction({ action: 'refreshList' })
                this.feedStatusLoading(1);
              } catch (error) {
                console.log("getData -> error", error)
                this.feedStatusLoading(3);
              }
            })
            .catch(error => {
              console.log("getData -> error", error)
              this.feedStatusLoading(3);
            });
        }
      );
    },

    sortList() {

    },

    // Sort list
    sortCurrentList(element) {
      if (this.pagination.directionSort === 'ASC') {
        this.pagination.directionSort = 'DESC'
      } else {
        this.pagination.directionSort = 'ASC'
      }
      let request = {
        orderBy: element,
        directionSort: this.pagination.directionSort,
        pageIndex: 1,
        pageSize: this.pagination.pageSize,
        outRowsNumber: this.pagination.outRowsNumber,
      };
    },

    // Search list
    searchList() {
      this.pagination.action = this.action.join();
      this.getData(this.pagination);
    },

    // Pagination size change
    handleSizeChange(pageSize) {
      let request = {
        querySearch: this.pagination.querySearch,
        username: this.pagination.username,
        orderBy: this.pagination.orderBy,
        directionSort: this.pagination.directionSort,
        pageIndex: 1,
        pageSize: pageSize,
        action: this.pagination.action,
        outRowsNumber: this.pagination.outRowsNumber,
      };
      this.getData(request);
    },

    // Pagination page change
    handleCurrentChange(element) {
      let request = {
        querySearch: this.pagination.querySearch,
        username: this.pagination.username,
        orderBy: this.pagination.orderBy,
        directionSort: this.pagination.directionSort,
        pageIndex: element,
        pageSize: this.pagination.pageSize,
        action: this.pagination.action,
        outRowsNumber: this.pagination.outRowsNumber,
      };
      this.getData(request);
    },

    // Map to component
    mapColumnUser(element) {
      let result = {};
      try {
        result = {
          username: element.username,
          registerAt: element.userCreatedAt,
          region: element.region,
          status: element.userStatus,
          restrictedUserLogin: element.restrictedUserLogin,
          restrictedUserForgotPassword: element.restrictedUserForgotPassword,
          restrictedUserChangeProfile: element.restrictedUserChangeProfile,
          restrictedUserChangePassword: element.restrictedUserChangePassword,
          restrictedUserResentActiveEmail: element.restrictedUserResentActiveEmail,
          restrictedUserRecharge: element.restrictedUserRecharge,
          restrictedImageBoot: element.restrictedImageBoot,
          restrictedImageShutDown: element.restrictedImageShutDown,
          restrictedImageChangeServicePackage: element.restrictedImageChangeServicePackage,
          restrictedImageClone: element.restrictedImageClone,
          restrictedImageConnect: element.restrictedImageConnect,
          restrictedImageCreate: element.restrictedImageCreate,
          restrictedImageDelete: element.restrictedImageDelete,
          restrictedImageRename: element.restrictedImageRename
        }
      } catch {
        result = {};
      }
      return result;
    },
  }
} 